import React, { Component } from 'react';
import './CSS/Footer.css';

import Pointer from '../Assets/pointer.png';

export class Footer extends Component {
    render() {
        return (
            <div className='footer'>
                <span><img className='footer-map-marker' src={Pointer} alt='Marker' /></span>
                <span>
                    Av. Fernando Simões Barbosa, 266, Salas 801/802 — Boa Viagem — Recife/PE — 51020-390.<br/>
                    Contate-nos: +55 (81) 3038-9496 | <i className='fa fa-whatsapp footer-wpp' /> +55 (81) 99729-9480 | atendimento@joriovalenca.com.br
                </span>
            </div>
        )
    }
}

export default Footer;
