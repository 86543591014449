import React, { Component } from 'react';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slide1 from '../Assets/Slides/slide-1.png';
import Slide2 from '../Assets/Slides/slide-2.png';
import Slide3 from '../Assets/Slides/slide-3.png';

export class Carousel extends Component {
    render() {
        var settings = {
            dots: true,
            arrows: false,
            speed: 1000,
            autoplay: true,
            autoplaySpeed: 5000,
            infinite: true,
            pauseOnHover: true,
            swipeToSlide: true
        };

        return (
            <div>
                <Slider {...settings}>
                    <div>
                        <img style={{width: '100%'}} src={Slide1} alt='Slide 1' />
                    </div>
                    <div>
                        <img style={{width: '100%'}} src={Slide2} alt='Slide 2' />
                    </div>
                    <div>
                        <img style={{width: '100%'}} src={Slide3} alt='Slide 3' />
                    </div>
                </Slider>
            </div>
        )
    }
}

export default Carousel;
