import React, { Component } from 'react';
// import InstagramFeed from 'instafeed';
import './CSS/InstaFeed.css';

export class InstaFeed extends Component {

    // componentDidMount() {
    //     (function(){
    //       new InstagramFeed({
    //           'username': 'joriovalenca.advogados',
    //           'container': document.getElementById("instafeed"),
    //           'display_profile': false,
    //           'display_biography': false,
    //           'display_gallery': true,
    //           'callback': null,
    //           'styling': true,
    //           'items': 3,
    //           'items_per_row': 3,
    //           'margin': 1 
    //       });
    //     })();
    // }

    render() {
        return (
            <div>
                {/* <div id='instafeed' /> */}
                <div className='insta-feed'><div className='elfsight-app-0fbf07f8-0181-466c-8177-5afe1c1a244c' /></div>
            </div>
        )
    }
}

export default InstaFeed;
