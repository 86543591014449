import React, { Component } from 'react';
import './CSS/Header.css';

import Logo from '../Assets/logo.png';
// import Separator from '../Assets/separator.png';
// import Pointer from '../Assets/pointer.png';

export class Header extends Component {

    home = () => {
        window.location.href = '/';
    }

    render() {
        return (
            <div className='header'>
                <div className='header-img-holder'><img className='header-logo' src={Logo} alt='Logo' onClick={this.home} /></div>
                {/* <div><img className='header-sep' src={Separator} alt='Separator' /></div>
                <div className='header-social'>
                    <span><img className='header-map-marker' src={Pointer} alt='Marker' /></span>
                    <span>
                        Av. Fernando Simões Barbosa, 266, Salas 801/802 — Boa Viagem — Recife/PE — 51020-390.<br/>
                        Contate-nos: +55 (81) 3038-9496 | <i className='fa fa-whatsapp header-wpp' /> +55 (81) 99729-9480 | +55 (81) 98733-9895 | atendimento@joriovalenca.com.br
                    </span>
                </div> */}
            </div>
        )
    }
}

export default Header;
