import React, { Component } from 'react';
import './CSS/Jurisprudencias.css';

import Footer from '../Components/Footer';
import Header from '../Components/Header';

export class Jurisprudencias extends Component {
    render() {
        return (
            <div className='bg'>
                <Header/>

                <div className='j-holder'>
                    <div>
                        <div className='j-title'><span>JURISPRUDÊNCIAS</span></div>
                        <div>
                            <ul>
                                <li><a href='http://portal.stf.jus.br/jurisprudencia/' target='_blank' rel='noopener noreferrer'>Supremo Tribunal Federal</a></li>
                                <li><a href='http://www.stj.jus.br/SCON/' target='_blank' rel='noopener noreferrer'>Superior Tribunal de Justiça</a></li>
                                <li><a href='http://www2.stm.jus.br/juris_internet/jurisprudencia.php' target='_blank' rel='noopener noreferrer'>Superior Tribunal Militar</a></li>
                                <li><a href='http://www.tse.jus.br/jurisprudencia' target='_blank' rel='noopener noreferrer'>Tribunal Superior Eleitoral</a></li>
                                <li><a href='http://www.tst.jus.br/jurisprudencia' target='_blank' rel='noopener noreferrer'>Tribunal Superior do Trabalho</a></li>
                                <li><a href='http://www.trf1.jus.br/Processos/JurisprudenciaOracle/jurisprudencia.php' target='_blank' rel='noopener noreferrer'>Tribunal Regional Federal da 1ª Região</a></li>
                                <li><a href='http://www10.trf2.jus.br/consultas/jurisprudencia/' target='_blank' rel='noopener noreferrer'>Tribunal Regional Federal da 2ª Região</a></li>
                                <li><a href='http://web.trf3.jus.br/base-textual' target='_blank' rel='noopener noreferrer'>Tribunal Regional Federal da 3ª Região</a></li>
                                <li><a href='http://jurisprudencia.trf4.jus.br/pesquisa/pesquisa.php?tipo=1' target='_blank' rel='noopener noreferrer'>Tribunal Regional Federal da 4ª Região</a></li>
                                <li><a href='https://www.trf5.jus.br/index.php/jurisprudencia-home' target='_blank' rel='noopener noreferrer'>Tribunal Regional Federal da 5ª Região</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <Footer/>
            </div>
        )
    }
}

export default Jurisprudencias;
